import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Button, Box } from "@mui/material";
import ContactImage from "../img/ContactImage.jpg";
//Material icon
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

interface HomeProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
}
const ContactUnder: React.FC<HomeProps> = ({ setValue }) => {
    const handleButtonClick = (newValue: string) => {
        setValue(newValue);
    };
    const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

    return (
        <Box sx={{ position: 'relative', width: '100%', height: isMobile ? '350px' : '450px', overflow: 'hidden' }}>
            <img src={ContactImage} alt="ContactImage" style={{ objectFit: 'cover', position: 'absolute', bottom: 0 }} />
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', color: 'white' }}>
                <Typography variant={isMobile ? "h3" : "h2"} sx={{ color: 'white' }}>CONTACT</Typography>
                <Typography variant="button" sx={{ fontWeight: "800", color: 'white' }}>
                    お問い合わせ
                </Typography>
                <Typography variant="subtitle1" sx={{ marginTop: "7%", color: "white" }}>
                    ご相談・ご質問・お見積りなど、お気軽にお問い合わせください。
                </Typography>
                <Typography variant={isMobile ? "h5" : "h4"} sx={{ marginTop: "8%", color: "white", fontWeight: "600" }}>
                    TriArrows合同会社
                </Typography>
                <Button
                    color="inherit"
                    sx={{ bgcolor: "orange", width: "200px", height: "50px", fontWeight: "800", fontSize: "15px", marginTop: "10%" }}
                    endIcon={<ArrowRightAltIcon />}
                    onClick={() => handleButtonClick("5")}
                >お問い合わせ</Button>
            </Box>
        </Box>
    )
}
export default ContactUnder;