import React, { useState } from 'react';
import { Box, Grid, TextField, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

interface HomeProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Contact: React.FC<HomeProps> = ({ setValue }) => {
    const handleButtonClick = (newValue: string) => {
        setValue(newValue);
    };

    const [IntroductioncompanyName, setIntroductioncompanyName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [position, setPosition] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [contactTtelephone, setContacTtelephone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = React.useState(false);

    const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        // Googleフォームの送信先URL
        const googleFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSdI0gN6NBF0I3sQV5PdiIUacrQx_q1erO_G-7sAnPjKNI6X4A/formResponse";

        // Googleフォームに送信するデータ
        const formData = new FormData();
        formData.append("entry.1396278977", IntroductioncompanyName);
        formData.append("entry.748022221", companyName);
        formData.append("entry.1744067089", position);
        formData.append("entry.826052730", contactPerson);
        formData.append("entry.2030371975", contactTtelephone);
        formData.append("entry.1168686236", contactEmail);
        formData.append("entry.676428737", message);

        // Googleフォームにデータを送信
        try {
            await fetch(googleFormUrl, {
                method: "POST",
                body: formData,
                mode: "no-cors", // ブラウザのCORSポリシーをバイパス                
            });

            handleClickOpen();
        } catch (error) {
            console.error("Error submitting form:", error);
        }
        // console.log("Form submitted:", { companyName, position, contactPerson, contactEmail, message });
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">
            <Box sx={{ width: "80vw", marginBottom: "10vh" }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <Typography variant={isMobile ? "h3" : "h2"} >
                        CONTACT
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom >
                        お問い合わせ
                    </Typography>
                    <Typography variant="subtitle1" mt={5}>
                        弊社にご関心をお持ちいただきまして、ありがとうございます。
                    </Typography>
                    <Typography variant="subtitle1">
                        業務に対するお見積もりやご相談、ご質問など、お気軽にお問い合わせください。
                    </Typography>
                    <Typography variant="subtitle1" mt={5}>
                    </Typography>

                    <form onSubmit={handleSubmit} style={{ width: isMobile ? "80vw" : "50vw" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="紹介会社名"
                                    value={IntroductioncompanyName}
                                    onChange={(e) => setIntroductioncompanyName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="会社名"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="役職"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="担当者"
                                    value={contactPerson}
                                    onChange={(e) => setContactPerson(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="担当者電話番号"
                                    value={contactTtelephone}
                                    onChange={(e) => setContacTtelephone(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="担当者メールアドレス"
                                    type="email"
                                    value={contactEmail}
                                    onChange={(e) => setContactEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    required
                                    label="自動化したい業務内容"
                                    multiline
                                    rows={4}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" >送信</Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Dialog open={open}>
                        <DialogTitle id="alert-dialog-title">
                            {"送信されました"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {<>会社名: {companyName}<br /></>}
                                {<>役職: {position}<br /></>}
                                {<>担当者: {contactPerson}<br /></>}
                                {<>担当者電話番号: {contactTtelephone}<br /></>}
                                {<>担当者メールアドレス: {contactEmail}<br /></>}
                                {<>自動化したい業務内容: {message}<br /></>}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleButtonClick("1")}>閉じる</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Box>
        </Grid>
    )
}
export default Contact;