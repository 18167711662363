import React from 'react';
import { Box, Grid, Card, CardContent, Typography, CardHeader } from '@mui/material';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import useMediaQuery from '@mui/material/useMediaQuery';

const cardData = [
    {
        icon: <IntegrationInstructionsIcon sx={{ fontSize: 50 }} />,
        title: '開発',
        text: 'お客様のニーズに合わせてRPAワークフローを設計し、効率化と自動化を実現するためのプロセスの自動化が含まれます。',
    },
    {
        icon: <EngineeringIcon sx={{ fontSize: 50 }} />,
        title: '保守',
        text: 'RPAシステムの安定性と信頼性を確保するために、保守サービスを提供します。',
    },
    {
        icon: <PrecisionManufacturingIcon sx={{ fontSize: 50 }} />,
        title: '運用',
        text: 'RPAシステムの運用を支援し、効果的な管理を提供します',
    }
];

function Service() {
    const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

    return (
        <Box sx={{ marginBottom: "10vh" }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Typography variant={isMobile ? "h3" : "h2"} >
                    SERVICE
                </Typography>
                <Typography variant="subtitle1" gutterBottom >
                    サービス
                </Typography>
                <Typography variant={isMobile ? "h6" : "h5"} sx={{ color: "orange" }} mt={4} mb={3}>
                    業務自動化導入支援
                </Typography>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                >
                    {cardData.map((data, index) => (
                        <Grid item >
                            <Card key={index} sx={{ width: isMobile ? "250px" : "350px" }}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center">
                                    <CardHeader
                                        title={data.title}
                                    />
                                    {data.icon}
                                </Grid>
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {data.text}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Box>
    )
}
export default Service;