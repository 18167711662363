import React from "react";
import { Grid, Typography, Button, Box, Paper } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

//Material icon
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

//画像
import CompanyImage from "../img/CompanyImage.jpg";
import ContactImage from "../img/ContactImage.jpg";
import BlogImage from '../img/BlogImage.jpg';

interface HomeProps {
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Home: React.FC<HomeProps> = ({ setValue }) => {
  const handleButtonClick = (newValue: string) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box sx={{ width: "80vw", marginBottom: "10%" }}>

        <Box sx={{ marginBottom: "5vh" }}>
          <Grid
            container
            direction="column"
            alignItems="center">
            <Typography variant={isMobile ? "h3" : "h2"}>ABOUT</Typography>
            <Typography variant="button" sx={{ fontWeight: "800" }}>
              私たちについて
            </Typography>
          </Grid>
        </Box>
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <img src={CompanyImage} alt="" style={{ width: isMobile ? "100%" : "40%" }} />
            <Paper
              sx={{ bgcolor: "orange", width: isMobile ? "100%" : "32%", height: isMobile ? "60%" : "380px", position: "relative", zIndex: 1 }}>
              <Typography sx={{ fontWeight: "600", margin: "5%", fontSize: isMobile ? "30px" : "45px" }}>
                TriArrows
              </Typography>
              <Typography variant="subtitle1" sx={{ margin: "7%" }}>
                会社、社員、お客様に焦点を当てる、IT会社およびコンサルティング企業です。<br />
                社員はお客様を最優先に考え、最高の価値を提供するために知識と技術を活かして精一杯取り組んでいます。
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Button
                  color="inherit"
                  sx={{ bgcolor: "white", width: "200px", height: "50px", fontWeight: "800", fontSize: "15px", margin: "5%" }}
                  endIcon={<ArrowRightAltIcon />}
                  onClick={() => handleButtonClick("4")}>
                  詳しくはこちら</Button>
              </Grid>
            </Paper>
          </Grid>
        </Box>
      </Box>

      {/* <Box sx={{ width: "80vw", marginBottom: "10%" }}>
        <Box sx={{ marginBottom: "5vh" }}>
          <Grid
            container
            direction="column"
            alignItems="center">
            <Typography variant={isMobile ? "h3" : "h2"}>BLOG</Typography>
            <Typography variant="button" sx={{ fontWeight: "800" }}>
              ブログ
            </Typography>
          </Grid>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start">
          <img src={BlogImage} alt="" style={{ width: isMobile ? "100%" : "60%", display: isMobile ? "block" : "none" }} />
          <Paper
            sx={{ bgcolor: "lightslategray", width: isMobile ? "100%" : "30%", height: isMobile ? "400px" : "380px", position: "relative", zIndex: 1 }}>
            <Typography sx={{ fontWeight: "600", margin: "7%", fontSize: isMobile ? "30px" : "40px" }}>
              新着記事
            </Typography>
            <Typography variant="subtitle1" sx={{ margin: "5%" }}>
              UiPathに特化した情報を提供しています。<br />
              最新のトピックやベストプラクティス、有益なヒントをQiitaにてお届けしています。
              UiPathを活用する皆様にとって価値ある情報源となるよう努めています。
            </Typography>
            <Button
              color="inherit"
              sx={{ bgcolor: "white", width: "200px", height: "50px", fontWeight: "800", fontSize: "15px", margin: "5%" }}
              endIcon={<ArrowRightAltIcon />}
              onClick={() => handleButtonClick("7")}>
              詳しくはこちら</Button>
          </Paper>
          <img src={BlogImage} alt="" style={{ width: isMobile ? "80%" : "40%", display: isMobile ? "none" : "block" }} />
        </Grid>
      </Box> */}
    </Grid>
  );
};
export default Home;
