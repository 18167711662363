import React from 'react';
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

function createData(
    name: string,
    calories: string,
) {
    return { name, calories, };
}

const rows = [
    createData('社名', 'TriArrows合同会社'),
    createData('設立', '2024年4月26日'),
    createData('役員数', '3名'),
    createData('本社所在地', ''),
    createData('事業内容', 'ITコンサルティング'),
];

function About() {
    const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center">

            <Box sx={{ width: "80vw", marginBottom: "10vh" }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">
                    <Typography variant={isMobile ? "h3" : "h2"} >
                        ABOUT
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom >
                        私たちについて
                    </Typography>
                    <Typography variant="h5" sx={{ color: "orange" }} mt={4}>
                        TriArrowsは
                    </Typography>
                    <Typography variant="subtitle1">
                        会社、社員、お客様に焦点を当てる、IT会社およびコンサルティング企業です。<br />
                        IT会社およびコンサルティング企業です。<br />
                        会社として、私たちは常に革新的なアプローチを追求し、業界でのリーダーシップを目指しています。<br />
                        社員は、その情熱と専門知識を活かして、お客様に最高の価値を提供することに取り組んでいます。<br />
                        お客様の成功を最優先に考え、協力関係を築くことに努めています。
                    </Typography>

                    <Typography variant={isMobile ? "h3" : "h2"} mt={5} >
                        OVERVIEW
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom mb={3}>
                        会社概要
                    </Typography>
                    <TableContainer component={Paper} sx={{ width: isMobile ? "80vw" : "40vw" }} >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.calories}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </Grid>
    )
}
export default About;