import React from 'react';
import { Box, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

function createData(
  name: string,
  calories: string,
) {
  return { name, calories, };
}

const rows = [
  createData('2024/04/26', 'UiPath Friends 2024 参加'),
  createData('2024/04/26', 'TriArrows会社設立'),
];

function News() {
  const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Box sx={{ width: "80vw", marginBottom: "10%" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
          <Typography variant={isMobile ? "h3" : "h2"} >
            NEWS
          </Typography>
          <Typography variant="subtitle1" gutterBottom >
            ニュース
          </Typography>
          <Typography variant="h5" sx={{ color: "orange" }} mt={5}>
          </Typography>

          <TableContainer component={Paper} sx={{ width: isMobile ? "90%" : "50%" }}>
            <Table sx={{ minWidth: isMobile ? 500 : 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.calories}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>

    </Grid>
  )
}
export default News;