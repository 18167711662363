import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

// サイトtsx
import Home from './Home';
import Service from './Service';
import About from './About';
import Contact from './Contact';
import News from './News';
import ContactUnder from './ContactUnder';
import Blog from './Blog';

// MUライブラリ
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

//画像
import MainImage from '../img/MainImage.jpg';

//TriArrowsアイコン
import TriArrows from '../img/TriArrowsIcon.png';

function App() {
  const [value, setValue] = React.useState('1');
  const isMobile = useMediaQuery('(max-width: 800px)'); // スマートフォン用の幅を設定

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabs = [
    { label: "Home", value: "1", component: <Home setValue={setValue} /> },        //ホーム
    { label: "Service", value: "2", component: <Service /> },      //サービス
    // { label: "Recruit", value: "3", component: <Home /> },      //採用
    { label: "About", value: "4", component: <About /> },       //～について/会社概要
    { label: "Contact", value: "5", component: <Contact setValue={setValue} /> },   //問い合わせ
    { label: "News", value: "6", component: <News /> },         //ニュース
    // { label: "Blog", value: "7", component: <Blog /> },         //ブログ
  ];

  return (
    <Box >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: "lavenderblush", position: "fixed", width: "100%", zIndex: 10 }}>
          <Tabs value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
            <img src={TriArrows} alt="" style={{ width: "10vh" }} />
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center">
          <Box>
            <img src={MainImage} alt="MainImage" style={{ objectFit: 'cover', width: '99vw', height: isMobile ? '350px' : '500px', }} />
            {tabs.map(tab => (
              <TabPanel key={tab.value} value={tab.value} sx={{ padding: "25px 0 0 0" }}>
                {tab.component}
              </TabPanel>
            ))}

          </Box>
        </Grid>
        <ContactUnder setValue={setValue} />
      </TabContext>
    </Box>
  );
}

export default App;
